.max-960 {
    max-width: 720px;
    width: 100%;
}

.centrar{
    display: flex;
    justify-content: center;
}
.card-container{
    margin-top: 0px !important;
}

.ppp{
    padding: 0px 30px;
}


.boton-select{
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 10px 0px;
    cursor: pointer;
}
.boton-select:hover{
    background-color: #dddddd;
}
.card-container .card-title {
    width: 100%;
}
.input-nobor{
    border: 0;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
}
.scrollbar-container{
    width: 100%;
}
.mt2{
    margin-top: 2rem;
}
.p30{
    padding:30px;
}