#content-menu-jamar {
    .main-manu {
        width: 94%;

        @media screen and (max-width: 500px) {
           width: 90%; 
           font-size: 4rem !important;
        }

        .content-menu-jamar {
            border-bottom: 1px solid #ffffff;
            justify-content: space-between;
            width: 100%;
            margin: 0;

            .btn-tabs-menu {
                color: #ffffff !important;
                border: none;
                font-family: OpenSans Regular;
                padding: 0px 6px;
                min-width: auto;
                text-transform: none;
                font-size: 18px;
                padding-bottom: 3px;
                position: relative;
                top: 3px;
                line-height: 45px;
                
                @media screen and (max-width: 420px) {
                    line-height: 38px;
                    font-size: 13px;
                }
            }

            .btn-tabs-menu:hover {
                border-bottom: 3px solid #ffffff;
                border-radius: 0px;
                position: relative;
                top: 2px;
            }

            .activate-btn {
                color: #fff;
                border: none !important;
                font-weight: bold;
                font-family: OpenSans Regular;
                span.MuiButton-label {
                    border-bottom: 3px solid #ffffff;
                    border-radius: 0px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}
