.description-forget-password {
    font-family: HKGrotesk Regular !important;
    font-size: 15px !important;
}

.content-img {
    text-align: center !important;
}

.content-recovery-password {
    & .conten-login .card-banner {
        padding: 54px 40px 172px 40px;
    }
}