body{
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

}
.max-960 {
    max-width: 720px;
    width: 100%;
}

.centrar{
    display: flex;
    justify-content: center;
}
.card-container{
    margin-top: 0px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

}

.ppp{
    padding: 0px 30px;
}

.boton-select{
    border-radius: 10px;
    border: 1px solid lightgray;
    padding: 5px 10px;
    cursor: pointer;
    height: auto !important;
    display: flex;
}
.boton-select:hover{
    background-color: #dddddd;
}
.card-container .card-title {
    width: 100%;
    margin-bottom: 15px;
 }
.input-nobor{
    border: 0;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
}
.scrollbar-container{
    width: 100%;
}
.mt2{
    margin-top: 2rem;
}
.p30{
    padding:30px;
}
.mpimgcontainer{
    width: 50px;
}
.mpimg{
    width: 100%;
}
.mptext{
    line-height: 2.3;
    font-weight: bold;
    font-size: 14px;
    margin-left: 2px;
    text-transform: uppercase;
}
.mb15{
    margin-bottom: 15px;
}
.card-title-desc{
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0.2rem;
}

.card-title-subdesc{
    margin-bottom: 0.5rem;
        font-size: 15px;
        color: #000;
}
.inputvalue{
    color: #2bb5ad;
}
.imgsmps{
    column-gap: 10px;
    padding-left: 15px;
}
.mpimgs{
    height: 50px;
    padding: 8px 8px 8px 0px;
    &:nth-child(4){
        padding: 4px 4px 4px 0px;
    }

}
.otrovalor{
    display: flex;
}

.pagavalor{
    margin-left: 8px;
}
.inputotrovalor{
    margin-top: 4px;
    .boton-select {
        padding: 8px 8px;
    }
}
.valorpagar{
    font-size: 15px;
    font-weight: 400;
}