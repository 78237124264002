:host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.img_card_small {
    width: 90%;
    margin: 10px 5px;
}
.img_card_big {
    width: 100%;
    margin: 10px 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 8px 0;
}

p {
    margin: 0;
}

.spacer {
    flex: 1;
}

.toolbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    align-items: center;
    background-color: #d8232a;
    color: white;
    font-weight: 600;
}

.toolbar img {
    margin: 16px 16px;
}

.toolbar #twitter-logo {
    height: 40px;
    margin: 0 16px;
}
.contendor_cedula {
    background-color: white;
    border-radius: 10px;
    -webkit-box-shadow: 8px 13px 40px 1px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 8px 13px 40px 1px rgba(0, 0, 0, 0.38);
    box-shadow: 8px 13px 40px 1px rgba(0, 0, 0, 0.38);
}
.img_panama_jamar {
    padding: 30px 0px 10px 0px;
}
.pagos-en-linea {
    color: #606060;
    font-family: "Helvetica Neue";
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 39px;
    text-align: center;
}

.toolbar-pagar #twitter-logo:hover {
    opacity: 0.8;
}
.pagar-mi-factura {
    height: 21px;
    width: 133px;
    color: #606060;
    font-family: "Helvetica Neue";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}

input,
select {
    background-color: #edecec;
}
.estamos-aqui-para-tu {
    color: #ffffff;
    font-family: "Helvetica Neue";
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    margin-bottom: 1rem;
}
.contentpagos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 120px);
}

.text_card {
    font-weight: 500;
    margin-top: 5px;
}
.titulo_pago {
    color: #606060;
    font-family: "Helvetica Neue";
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0;
}
svg.material-icons {
    height: 24px;
    width: auto;
}

svg.material-icons:not(:last-child) {
    margin-right: 8px;
}

.card svg.material-icons path {
    fill: #888;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
}

.card-pagar {
    border-radius: 4px;
    border: 1px solid #eee;
    background-color: #fafafa;
    height: 40px;
    width: 200px;
    margin: 0 8px 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
}

.card-container .card:not(:last-child) {
    margin-right: 0;
}

.card.card-small {
    height: 16px;
    width: 168px;
}

.card-container .card:not(.highlight-card) {
    cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(black, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
}

.card.highlight-card {
    background-color: #787a7c;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
}

.card.card.highlight-card span {
    margin-left: 60px;
}

svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
}

svg#rocket-smoke {
    height: calc(100vh - 95px);
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
}

a,
a:visited,
a:hover {
    color: #1976d2;
    text-decoration: none;
}

a:hover {
    color: #125699;
}

.terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
}

.terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
}

.terminal pre {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
}

.circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
}

.circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

footer {
    padding: 10px;
    align-items: center;
    line-height: 20px;
}

footer a {
    align-items: center;
}

.github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27, 31, 35, 0.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol;
}

.github-star-badge:hover {
    background-image: linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%);
    border-color: rgba(27, 31, 35, 0.35);
    background-position: -0.5em;
}

.github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
}

/* Responsive Styles */

.credit-card-div span {
    padding-top: 10px;
}
.credit-card-div img {
    padding-top: 30px;
}
.credit-card-div .small-font {
    font-size: 9px;
}
.credit-card-div .pad-adjust {
    padding-top: 10px;
}

.max-960 {
    max-width: 960px;
}
.izquierdo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.text-red {
    color: #d8222b !important;
}

.identifica {
    color: #606060;
    font-family: "Helvetica Neue";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
}
