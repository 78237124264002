.conten-login {
    height: inherit;
    width: 100%;
    .card-banner-code {
        background-color: #c52632 !important;
        position: relative;
        left: 1px;
        justify-content: center !important;
        color: white;
        padding: 80px 40px;
        .tittle,
        .text {
            text-align: center;
        }
    }
    .card-form-code {
        border: 1px solid #ccc;
        font-family: OpenSans Regular;
        // padding: 70px 70px 55px 70px !important;
        text-align: left;
        .center-log {
            .content-btn-log {
                .MuiButton-containedPrimary {
                    background-color: #c42531 !important;
                }
            }
        }
    }
}

#forgot-pass {
    text-decoration: underline;
    font-weight: bold;
    font-size: 0.75rem;
}

#forgot-pass:hover {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    cursor: pointer;
}

#sendCode {
    display: block;
    font-size: 0.7rem;
    text-align: end;
    padding-top: 10px;
    cursor: pointer;
    text-decoration: underline;
}

h6 {
    font-family: HKGrotesk Regular !important;
}

@media (min-width: 340px) {
    .conten-login {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-banner-code {
                .tittle,
                .text {
                    font-size: 0.8rem;
                    line-height: 14px;
                }
                .text {
                    margin-top: 10px;
                }
            }
            .card-form-code {
                position: absolute;
                top: 238px;
                padding: 20px;
                padding-bottom: 40px;
                border-radius: 19px;
                box-shadow: 0px 3px 6px #00000045;
                .options {
                    border-bottom: 1px solid #ccc;
                    span {
                        font-size: 0.68rem;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
                h4 {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
                label,
                input {
                    font-size: 0.8rem;
                }
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.8rem;
                        margin: 0px;
                    }
                    p {
                        margin-top: 2px;
                        text-align: right;
                    }
                }
                .center-log {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    .content-btn-log {
                        position: absolute;
                        width: max-content;
                        &:nth-child(1) {
                            order: 1;
                        }
                        &:nth-child(2) {
                            order: 2;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(3) {
                            order: 3;
                            margin-bottom: 1rem;
                        }
                        h6 {
                            font-size: 0.7rem;
                            display: block;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-login {
        height: fit-content;
        .card-banner-code {
            .tittle,
            .text {
                font-size: 0.9rem;
                line-height: 16px;
            }
        }
        .card-form-code {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }
}

@media (min-width: 768px) {
    .conten-login {
        height: auto;
        .envoltura {
            .card-banner-code {
                .tittle {
                    font-size: 1.1rem;
                    line-height: 21px;
                }
                .text {
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
            .card-form-code {
                padding: 35px 35px;
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.7rem;
                    }
                    p {
                        margin-top: 3px;
                    }
                }
                .cont-buttons {
                    margin-top: 1rem;
                    .center-horizontal {
                        &:nth-child(1) {
                            order: 1;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(2) {
                            order: 2;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(3) {
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}

.info-security-code {
    font-size: 20px !important;
    line-height: 1.2 !important;
}