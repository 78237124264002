.MuiButton-root {
    text-transform: capitalize !important;
}

.text-center {
    text-align: center !important;
}

.display-block {
    display: block !important;
}

.btn {
    font-family: OpenSans Regular !important;
}

.btn-primary-app {
    background-color: #02b5ad !important;
    color: white !important;
}

.btn-secondary-app {
    background-color: white !important;
    color: #02b5ad !important;
    border: 1px solid #02b5ad !important;
}

.center-horizontal {
    display: flex !important;
    justify-content: center !important;
}

.font-hk-regular {
    font-family: OpenSans Regular !important;
}

.f-s-2 {
    font-size: 2px !important;
}

.f-s-4 {
    font-size: 4px !important;
}

.f-s-6 {
    font-size: 6px !important;
}

.f-s-8 {
    font-size: 8px !important;
}

.f-s-10 {
    font-size: 10px !important;
}

.f-s-11 {
    font-size: 11px !important;
}

.f-s-12 {
    font-size: 12px !important;
}

.f-s-13 {
    font-size: 13px !important;
}

.f-s-14 {
    font-size: 14px !important;
}

.f-s-15 {
    font-size: 15px !important;
}

.f-s-16 {
    font-size: 16px !important;
}

.f-s-17 {
    font-size: 17px !important;
}

.f-s-18 {
    font-size: 18px !important;
}

.f-s-19 {
    font-size: 19px !important;
}

.f-s-20 {
    font-size: 20px !important;
}

.f-s-25 {
    font-size: 25px !important;
}