.disable-recurrency {
    margin-top: 20px;
    a {
        color: #000000a3;
        text-decoration: none;
        &:visited, &:hover{
            color: #000000a3;
            text-decoration: none;

        }
    }

}