.pagination-container {
    display: flex;
    justify-content: center;

    .rc-pagination-item-active {
        background-color: white;
    }
}

        #card {
            width: 100% !important;
        }
        
        #panel1a-header {
            padding: 0;
        }
        
        .header-acordeon {
            width: 100%;
        }
        
        .content-estado {
            margin-right: 30px;
        }
        
        .fecha {
            margin-top: 8px;
            font-family: OpenSans Regular;
            font-size: 14px;
            @media screen and (max-width: 520px) {
                font-size: .7rem;
            }
        }
        
        .boton-producto {
            font-size: 1rem !important;
            @media screen and (max-width: 500px) {
                font-size: .8rem !important;
            }
            @media screen and (max-width: 380px) {
                font-size: .6rem !important;
            }
        }
        
        .modal-terminos-condiciones {
            width: 50%;
            margin: auto;
            @media screen and (max-width: 480px) {
                font-size: .8rem !important;
            }
        }
        
        .terminos-condiciones {
            background-color: #888 !important;
            color: white !important;
            border-radius: 35px !important;
            width: 100%;
            &:hover {
                background-color: white !important;
                color: #888 !important;
            }
        }
        
        .solicitar-garantia {
            background-color: #ff9804 !important;
            color: white !important;
            border-radius: 35px !important;
            width: 100%;
            &:hover {
                background-color: white !important;
                color: #ff9804 !important;
            }
        }
        
        .califica-producto {
            background-color: #ffd110 !important;
            border-radius: 35px !important;
            color: white !important;
            width: 100%;
            &:hover {
                background-color: white !important;
                color: #ffd110 !important;
            }
        }
        
        .btn-tracking {
            background-color: #00B5AC !important;
            border-radius: 22px !important;
            color: white !important;
            width: 100%;
            &:hover {
                background-color: white !important;
                color: #00B5AC !important;
            }
        }
        
        .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
            position: relative;
            right: 20px
        }
        
        .titulo-propiedad,
        .valor-propiedad,
        .estado-propiedad {
            @media screen and (max-width: 520px) {
                margin: 0;
                padding: 0;
                font-size: .7rem;
            }
        }
        
        .valor-propiedad {
            font-weight: bold;
        }
        
        .warranty-info {
            margin-left: 30px;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 13px;
                left: -37px;
                height: 26px;
                width: 26px;
                background-color: white;
                border-radius: 50%;
            }
            &.entregada:after {
                border: 3px solid #bababa;
                background-color: #bababa;
            }
            &.procesando:after {
                border: 3px solid #0cb4ac;
            }
            @media screen and (max-width: 520px) {
                font-size: .7rem !important;
            }
        }
        
        .text-estado-factura {
            border: 1px solid;
            border-radius: 3px;
            font-size: 14px;
            padding: 2.6px;
            &.procesando {
                color: #0cb4ac;
            }
            &.entregada {
                // color: #bababa;
                font-weight: bold;
            }
            @media screen and (max-width: 420px) {
                font-size: 7px;
                padding: 3px;
            }
        }
        
        .title-product {
            color: #1e374f !important;
            font-weight: 600 !important;
            font-family: OpenSans Regular !important;
            font-size: 14px !important;
        }
        
        .contenedor-guarantee {
            position: relative;
            padding-left: 23px;
            padding-right: 10px;
            .guarantee {
                &:last-child {
                    .contenedor-imag {
                        border: none !important;
                    }
                }
                // img {
                //     // width: 100%;
                //     // max-height: 82%;
                // }
                ul {
                    padding: 0px;
                    li {
                        list-style: none;
                        span {
                            font-size: 0.8rem;
                            &:first-child {
                                padding-left: 0px;
                                color: #14394f;
                                font-family: OpenSans Regular;
                            }
                            &:last-child {
                                padding-left: 0px;
                                text-align: end;
                                color: #14394f;
                                font-family: OpenSans SemiBold;
                            }
                            &.title {
                                text-align: left;
                                color: #333333;
                                font-family: OpenSans SemiBold;
                            }
                        }
                        button {
                            padding: 0px;
                            width: 95%;
                            >span {
                                color: #fff !important;
                                font-family: OpenSans Light !important;
                            }
                        }
                    }
                }
                &.solicitado {
                    .contenedor-imag {
                        border-left: 2px dashed #bababa !important;
                    }
                    ul {
                        li {
                            button {
                                background: #0cb4ac;
                                color: #fff;
                            }
                        }
                    }
                }
                &.solicitar {
                    .contenedor-imag {
                        border-left: 2px dashed #bababa !important;
                    }
                    ul {
                        li {
                            button {
                                background: #ff9804;
                                color: #fff;
                            }
                        }
                    }
                }
                &.terminos {
                    .contenedor-imag {
                        border-left: 2px dashed #bababa !important;
                    }
                    ul {
                        li {
                            button {
                                background: #bababa;
                                color: #ffffff;
                            }
                        }
                    }
                }
                & .btn-garantia-proceso {
                    background-color: #00b5ad !important;
                    color: #ffffff !important;
                    border-radius: 22px;
                    &:hover {
                        outline: none !important;
                    }
                    &:focus {
                        outline: none !important;
                    }
                }
            }
        }
        
        .terminosy {
            .content-terminios {
                ul {
                    padding-left: 19px;
                }
                h3 {
                    font-weight: bold;
                }
            }
        }
        
        .desc-problem {
            h4 {
                font-family: OpenSans Bold;
            }
            h5 {
                font-family: OpenSans Regular;
            }
            h4,
            h5 {
                color: #333333;
            }
            form {
                .MuiFormControl-root {
                    .MuiInputBase-input,
                    label {
                        font-family: OpenSans Regular;
                    }
                }
                .content-btn-problem {
                    position: absolute;
                    padding: 10px 0px;
                    width: max-content;
                    button {
                        color: #ffffff;
                        font-family: OpenSans SemiBold;
                        &:first-child {
                            background-color: #333333;
                        }
                        &:last-child {
                            background-color: #c42531;
                        }
                    }
                }
            }
        }
        
        .evidenc-photo {
            h4 {
                font-family: OpenSans Bold;
            }
            h5 {
                font-family: OpenSans Regular;
            }
            h4,
            h5 {
                color: #333333;
            }
            .main-evidenc {
                p {
                    margin-bottom: 0px;
                    font-family: OpenSans Regular;
                    color: #202020;
                }
                .cover-background {
                    background: #333333;
                    border-radius: 10px;
                    &::before {
                        font-family: OpenSans Bold;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        background: red;
                        border-radius: 50%;
                    }
                    .content-img-evidenc {
                        align-content: center;
                        height: 150px;
                        img {
                            max-height: 100px;
                        }
                    }
                    .btn-add-img {
                        border-radius: 22px;
                        background-color: #ffffff;
                        font-family: OpenSans SemiBold;
                        color: #333333;
                        text-transform: capitalize;
                    }
                }
                >div:nth-child(1) {
                    .cover-background {
                        &::before {
                            content: "1";
                        }
                    }
                }
                >div:nth-child(2) {
                    .cover-background {
                        &::before {
                            content: "2";
                        }
                    }
                }
                >div:nth-child(3) {
                    .cover-background {
                        &::before {
                            content: "3";
                        }
                    }
                }
                >div:nth-child(4) {
                    .cover-background {
                        &::before {
                            content: "4";
                        }
                    }
                }
                >div:nth-child(5) {
                    .cover-background {
                        &::before {
                            content: "5";
                        }
                    }
                }
                .content-btn-evidence {
                    // background-color: #ffffff;
                    position: absolute;
                    // box-shadow: 0px 3px 6px #00000045;
                    // border-radius: 22px;
                    // padding: 10px 0px;
                    width: max-content;
                    button {
                        color: #ffffff;
                        font-family: OpenSans SemiBold;
                        &:first-child {
                            background-color: #333333;
                        }
                        &:last-child {
                            background-color: #c42531 !important;
                        }
                    }
                }
            }
        }
        
        .info-biosecurity {
            h4 {
                font-family: OpenSans Bold;
            }
            img {
                width: 100%;
                margin-bottom: 20px;
            }
            h5 {
                font-family: OpenSans SemiBold;
            }
            p,
            .acepterms {
                font-family: OpenSans Regular;
            }
            h4,
            h5,
            p {
                color: #333333;
            }
            .items-security {
                span.item-num {
                    margin-bottom: 0px;
                    font-family: OpenSans Bold;
                    position: initial;
                    left: 16px;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    font-size: 14px;
                    color: #fff;
                    background: #00b5ad;
                    display: inline-block;
                }
            }
            .items-security {
                p {
                    &:nth-child(2) {
                        &::before {
                            content: "1";
                        }
                    }
                    &:nth-child(3) {
                        &::before {
                            content: "2";
                        }
                    }
                    &:nth-child(4) {
                        &::before {
                            content: "3";
                        }
                    }
                    &:nth-child(5) {
                        &::before {
                            content: "4";
                        }
                    }
                }
            }
            .acepterms {
                line-height: 0px;
                margin-bottom: 15px;
                span {
                    padding-left: 0px;
                }
            }
            .content-btn-bio {
                // background-color: #ffffff;
                position: absolute;
                // box-shadow: 0px 3px 6px #00000045;
                border-radius: 22px;
                padding: 10px;
                width: max-content;
                button {
                    color: #ffffff;
                    font-family: OpenSans SemiBold;
                    &:first-child {
                        background-color: #333333;
                    }
                    &:last-child {
                        background-color: #c42531;
                    }
                }
            }
        }
        
        .message-gratulations {
            h4 {
                font-family: OpenSans Regular;
            }
            img {
                width: 100%;
                margin-bottom: 20px;
            }
            .items-gratulations {
                h5 {
                    font-size: 1.25rem;
                    font-family: OpenSans Regular;
                }
                p {
                    margin-bottom: 0px;
                    &::before {
                        font-family: OpenSans Bold;
                        position: absolute;
                        left: 16px;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        font-size: 14px;
                        color: #fff;
                        background: #00b5ad;
                    }
                }
            }
            .items-gratulations {
                p {
                    &:nth-child(2) {
                        &::before {
                            content: "1";
                        }
                    }
                    &:nth-child(3) {
                        &::before {
                            content: "2";
                        }
                    }
                }
            }
            .content-btn-gratulations {
                // background-color: #ffffff;
                position: absolute;
                // box-shadow: 0px 3px 6px #00000045;
                border-radius: 22px;
                padding: 10px;
                width: max-content;
                button {
                    color: #ffffff;
                    font-family: OpenSans SemiBold;
                    background-color: #c42531;
                }
            }
        }
        
        .direction-detail {
            form {
                .MuiFormControl-root {
                    width: 100%;
                    .MuiInputBase-input,
                    label {
                        font-family: OpenSans Regular;
                    }
                }
                .content-btn-direccion {
                    position: absolute;
                    padding: 10px 0px;
                    width: max-content;
                    button {
                        color: #ffffff;
                        font-family: OpenSans SemiBold;
                        &:first-child {
                            background-color: #333333;
                        }
                        &:last-child {
                            background-color: #c42531;
                        }
                    }
                }
            }
        }
        
        @media (min-width: 340px) {
            .contenedor-guarantee {
                padding-left: 19px;
                padding-right: 5px;
                .guarantee {
                    .contenedor-imag {
                        padding: 0px 8px;
                    }
                    ul {
                        li {
                            span {
                                &:first-child {
                                    font-size: 0.6rem;
                                }
                                &:last-child {
                                    font-size: 0.6rem;
                                }
                                &.title {
                                    font-size: 0.8rem;
                                }
                            }
                            button {
                                padding: 0px;
                                width: 95%;
                                >span {
                                    font-size: 0.58rem !important;
                                }
                            }
                        }
                    }
                    &::before {
                        left: 0px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            .terminosy {
                .content-terminios {
                    h3 {
                        font-size: 0.9rem;
                    }
                    p {
                        font-size: 0.7rem;
                        margin-bottom: 8px;
                    }
                    ul {
                        li {
                            font-size: 0.7rem;
                        }
                    }
                    .acepterms {
                        font-size: 0.7rem;
                        text-align: center;
                        line-height: 0px;
                        span {
                            padding-left: 0px;
                        }
                    }
                }
                button {
                    font-size: 0.8rem;
                }
            }
            .desc-problem {
                h4 {
                    font-size: 0.9rem;
                }
                h5 {
                    font-size: 0.85rem;
                }
                form {
                    .MuiFormControl-root {
                        label.Mui-focused,
                        label.MuiFormLabel-filled {
                            font-size: 0.9rem;
                        }
                        .MuiInputBase-input,
                        label {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .evidenc-photo {
                h4 {
                    font-size: 0.9rem;
                }
                h5 {
                    font-size: 0.85rem;
                }
                .main-evidenc {
                    p {
                        font-size: 0.8rem;
                    }
                    .btn-add-img {
                        font-size: 0.7rem;
                        margin-bottom: 8px;
                        padding: 0px 25px;
                    }
                }
            }
            .info-biosecurity {
                h4 {
                    font-size: 0.9rem;
                }
                h5 {
                    font-size: 0.85rem;
                }
                .items-security {
                    p {
                        font-size: 0.8rem;
                        margin-left: 27px;
                        line-height: 15px;
                        margin-bottom: 10px;
                        &::before {
                            font-size: 0.8rem;
                            width: 17px;
                            height: 17px;
                            line-height: 16px;
                        }
                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }
                .content-btn-bio {
                    bottom: -66px;
                }
                .acepterms {
                    font-size: 0.8rem;
                }
            }
            .message-gratulations {
                h4 {
                    font-size: 0.9rem;
                }
                .items-gratulations {
                    h5 {
                        font-size: 0.9rem;
                    }
                    p {
                        font-size: 0.8rem;
                        margin-left: 27px;
                        line-height: 15px;
                        margin-bottom: 10px;
                        &::before {
                            font-size: 0.8rem;
                            width: 17px;
                            height: 17px;
                            line-height: 16px;
                        }
                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .direction-detail {
                form {
                    .MuiFormControl-root {
                        label.Mui-focused,
                        label.MuiFormLabel-filled {
                            font-size: 0.9rem;
                        }
                        .MuiInputBase-input,
                        label {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
        
        @media (min-width: 576px) {
            .contenedor-guarantee {
                padding-left: 21px;
                padding-right: 8px;
                .guarantee {
                    .contenedor-imag {
                        padding: 0px 8px;
                    }
                    ul {
                        li {
                            span {
                                &:first-child {
                                    font-size: 0.7rem;
                                }
                                &:last-child {
                                    font-size: 0.7rem;
                                }
                                &.title {
                                    font-size: 0.8rem;
                                }
                            }
                            button {
                                padding: 0px;
                                >span {
                                    font-size: 0.64rem !important;
                                }
                            }
                        }
                    }
                    &::before {
                        left: 0px;
                        width: 13px;
                        height: 13px;
                    }
                }
            }
            .terminosy {
                .content-terminios {
                    h3 {
                        font-size: 1rem;
                    }
                    p {
                        font-size: 0.8rem;
                        margin-bottom: 8px;
                    }
                    ul {
                        li {
                            font-size: 0.8rem;
                        }
                    }
                    .acepterms {
                        font-size: 0.8rem;
                        text-align: center;
                        line-height: 0px;
                        span {
                            padding-left: 0px;
                        }
                    }
                }
                button {
                    font-size: 0.85rem;
                }
            }
            .desc-problem {
                h4 {
                    font-size: 1rem;
                }
                h5 {
                    font-size: 0.9rem;
                }
            }
            .evidenc-photo {
                h4 {
                    font-size: 1rem;
                }
                h5 {
                    font-size: 0.9rem;
                }
            }
            .info-biosecurity {
                h4 {
                    font-size: 1rem;
                }
                h5 {
                    font-size: 0.9rem;
                }
            }
        }
        
        @media (min-width: 992px) {
            .contenedor-guarantee {
                padding-left: 22px;
                padding-right: 8px;
                .guarantee {
                    .contenedor-imag {
                        padding: 0px 10px;
                    }
                    ul {
                        li {
                            span {
                                &:first-child {
                                    font-size: 0.8rem;
                                }
                                &:last-child {
                                    font-size: 0.8rem;
                                    b {
                                        font-size: 0.65rem;
                                    }
                                }
                                &.title {
                                    font-size: 0.85rem;
                                }
                            }
                            button {
                                padding: 0px;
                                >span {
                                    font-size: 0.7rem !important;
                                }
                            }
                        }
                    }
                    &::before {
                        left: 0px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
            .terminosy {
                .content-terminios {
                    h3 {
                        font-size: 1.1rem;
                    }
                    p {
                        font-size: 0.85rem;
                        margin-bottom: 9px;
                    }
                    ul {
                        li {
                            font-size: 0.85rem;
                        }
                    }
                    .acepterms {
                        font-size: 0.85rem;
                        text-align: center;
                        line-height: 0px;
                    }
                }
                button {
                    font-size: 0.9rem;
                }
            }
            .desc-problem {
                h4 {
                    font-size: 1.1rem;
                }
                h5 {
                    font-size: 0.95rem;
                }
                form {
                    .MuiFormControl-root {
                        label.Mui-focused,
                        label.MuiFormLabel-filled {
                            font-size: 0.9rem;
                        }
                        .MuiInputBase-input,
                        label {
                            font-size: 0.95rem;
                        }
                    }
                }
            }
            .evidenc-photo {
                h4 {
                    font-size: 1.1rem;
                }
                h5 {
                    font-size: 0.95rem;
                }
                .main-evidenc {
                    p {
                        font-size: 0.9rem;
                    }
                    .btn-add-img {
                        font-size: 0.8rem;
                        margin-bottom: 12px;
                        padding: 3px 15px;
                    }
                }
            }
            .info-biosecurity {
                h4 {
                    font-size: 1.1rem;
                }
                h5 {
                    font-size: 0.95rem;
                }
                .items-security {
                    p {
                        font-size: 0.9rem;
                        padding-left: 30px;
                        line-height: 17px;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 25px;
                        }
                        &::before {
                            font-size: 0.9rem;
                        }
                    }
                }
                .content-btn-bio {}
                .acepterms {
                    font-size: 0.9rem;
                }
            }
            .message-gratulations {
                h4 {
                    font-size: 1.25rem;
                }
                .items-gratulations {
                    p {
                        font-size: 0.9rem;
                        padding-left: 30px;
                        line-height: 17px;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 25px;
                        }
                        &::before {
                            font-size: 0.9rem;
                        }
                    }
                }
            }
            .direction-detail {
                form {
                    .MuiFormControl-root {
                        label.Mui-focused,
                        label.MuiFormLabel-filled {
                            font-size: 0.9rem;
                        }
                        .MuiInputBase-input,
                        label {
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }
        
        .date-default-page {
            .change-date-and-working-date-and-directions {
                color: "#00B5AD";
                font-size: "17px";
                font-weight: "600";
            }
            .change-date-and-working-date-and-directions:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        
        .btn-accept-default {
            background-color: #c2232f !important;
        }
        
        .btn-cancel-default {
            background-color: #333333 !important;
        }
        
        .buttons-accept-cancel {
            color: white !important;
            border-radius: 22px !important;
            font-weight: 600 !important;
            font-family: OpenSans Regular !important;
            text-transform: none !important;
        }
        
        .selects-width60-left {
            width: 60% !important;
            text-align: left;
        }
        
        .width100 {
            width: 100% !important;
        }
        
        .filter-all-products {
            span {
                color: #333333;
                font-size: 17px;
                font-weight: 500;
                top: 3px;
                position: relative;
            }
            span:hover {
                cursor: pointer !important;
            }
            span.filter-all-selected {
                color: black !important;
                text-decoration: underline !important;
                font-weight: 600 !important;
            }
        }
        
        .filters-products {
            font-size: 14px;
        }
        
        .btn-filters {
            float: right !important;
            text-transform: none !important;
        }
        
        .filters-activated {
            color: #c52632 !important;
            background-color: gainsboro !important;
        }
        
        // .icon-btn-filters {
        // }
        //-//////////////////////////// ESTILOS PRODUCTOS ///////////////////////////////////////////-//
        .MuiPaper-elevation1 {
            box-shadow: none !important;
        }
        
        .MuiPaper-root {
            box-shadow: none;
            border: 0px;
            .header-acordeon {
                .compra-id {
                    >span {
                        display: block;
                        font-family: OpenSans Regular;
                        &.orden {
                            font-weight: bold;
                            font-size: 0.85rem;
                        }
                        &.fecha {
                            font-size: 0.7rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        
        .MuiCollapse-container {
            padding: 0px 22px;
            .MuiCollapse-wrapper {
                padding-bottom: 15px;
                .detalle-compra {
                    border-top: 1px solid #e4e4e4;
                    border-bottom: 1px solid #e4e4e4;
                    padding-bottom: 0px;
                    .MuiTypography-root {
                        width: 100%;
                        h6 {
                            font-family: OpenSans Regular;
                            font-weight: bold;
                            color: #14394f;
                        }
                        .detalle-content {
                            ul {
                                padding-left: 0px;
                                li {
                                    list-style: none;
                                    padding: 2px 0px;
                                    span {
                                        font-size: 0.64rem;
                                        font-family: OpenSans Regular;
                                        color: #14394f;
                                        &.rating {
                                            span {
                                                &.MuiRating-root {
                                                    span {
                                                        .MuiRating-iconFilled {
                                                            color: #ffb400 !important;
                                                        }
                                                        .MuiRating-iconEmpty {
                                                            color: #bdbdbd !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:last-child {
                                            font-weight: 500;
                                        }
                                        .btn-compra {
                                            border: 1px solid #333333;
                                            border-radius: 3px;
                                            color: #333333;
                                            margin-bottom: 0px;
                                            text-align: center;
                                            cursor: pointer;
                                        }
                                        .MuiBox-root {
                                            margin-bottom: 0px;
                                            line-height: 21px;
                                            .MuiRating-label {
                                                margin-bottom: 0px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .MuiAccordionSummary-content {
            margin: 0 !important;
            padding: 0 !important;
        }
        
        .MuiAccordionDetails-root {
            margin: auto 0;
            padding: auto 0;
        }
        
        .acordion-producto {
            margin: 10px auto;
            box-shadow: 0 0 transparent !important;
            border-radius: 0 !important;
        }
        
        .acordion-producto.acordion-proceso {
            border-radius: 0 !important;
        }
        
        .acordion-producto.acordion-last {
            border-radius: 0 !important;
        }
        
        .acordion-producto.acordion-last.acordion-expandido {
            border-radius: 0 !important;
        }
        
        .acordion-expandido .compra-square {
            top: 2px;
        }
        
        .btn-tag-estado {
            font-size: .8rem !important;
            padding: 0 !important;
            border-radius: 0 !important;
            font-weight: bold;
            text-align: center;
            position: relative;
            bottom: 3px;
            @media screen and (max-width: 350px) {
                bottom: 5px;
            }
        }
        
        .btn-en_proceso {
            background-color: #fff !important;
            color: #0cb4ac !important;
            border-bottom: 2px solid #0cb4ac;
        }
        
        .btn-no_en_proceso {
            background-color: #fff !important;
            color: #bababa !important;
            border-bottom: 2px solid #bababa;
        }
        
        .compra-square {
            display: flex;
            position: absolute;
            top: 3px;
            left: -25px;
        }
        
        .detalle-compra {
            margin-left: 10px;
        }
        
        .MuiIconButton-edgeEnd {
            margin-right: -21px !important;
        }
        
        @media (max-width: 671px) {
            .compra-square {
                display: flex;
                position: absolute;
                top: 0px;
                left: -25px;
            }
            .acordion-expandido .compra-square {
                top: 0px;
            }
        }
        
        @media (min-width: 300px) {
            .MuiPaper-root {
                .MuiButtonBase-root.MuiIconButton-root {
                    padding: 0px;
                }
                .header-acordeon {
                    .compra-id {
                        padding: 0px;
                        >span {
                            &.orden {
                                font-size: 0.6rem;
                            }
                            &.fecha {
                                font-size: 0.5rem;
                            }
                        }
                    }
                }
            }
            .MuiCollapse-container {
                padding: 0px;
                .MuiCollapse-wrapper {
                    padding-bottom: 15px;
                    padding-left: 15px;
                    @media screen and (max-width: 420px) {
                        padding-left: 10px;
                    }
                    .detalle-compra {
                        padding-bottom: 5px;
                        .MuiTypography-root {
                            h6 {
                                font-size: 0.52rem;
                            }
                            .img-content {
                                padding-left: 0px;
                                max-height: 110px;
                                img {
                                    max-height: 110px;
                                }
                            }
                            .detalle-content {
                                padding-left: 0px;
                                ul {
                                    padding-left: 0px;
                                    margin-bottom: 0px;
                                    li {
                                        padding: 2px 0px;
                                        span {
                                            font-size: 0.47rem;
                                            &:last-child,
                                            &:first-child {
                                                padding-right: 0px;
                                            }
                                            .btn-compra {
                                                font-size: 0.41rem;
                                            }
                                            .MuiBox-root {
                                                line-height: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 340px) {
            .MuiPaper-root {
                .header-acordeon {
                    .compra-id {
                        padding: 0px;
                        >span {
                            &.orden {
                                font-size: 0.7rem;
                            }
                            &.fecha {
                                font-size: 0.6rem;
                            }
                        }
                    }
                }
            }
            .MuiCollapse-container {
                .MuiCollapse-wrapper {
                    padding-bottom: 15px;
                    .detalle-compra {
                        padding-bottom: 5px;
                        .MuiTypography-root {
                            h6 {
                                font-size: 0.6rem;
                            }
                            .img-content {
                                padding-left: 0px;
                                max-height: 110px;
                            }
                            .detalle-content {
                                padding-left: 0px;
                                ul {
                                    li {
                                        padding: 2px 0px;
                                        span {
                                            font-size: 0.62rem;
                                            &:last-child,
                                            &:first-child {
                                                padding-right: 0px;
                                            }
                                            .btn-compra {
                                                font-size: 0.54rem;
                                            }
                                            .MuiBox-root {
                                                line-height: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 576px) {
            .MuiPaper-root {
                .MuiButtonBase-root.MuiIconButton-root {
                    padding: 0px;
                }
                .header-acordeon {
                    .compra-id {
                        padding: 0px;
                        >span {
                            &.orden {
                                font-size: 0.8rem;
                            }
                            &.fecha {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
            .MuiCollapse-container {
                padding: 0px;
                .MuiCollapse-wrapper {
                    padding-bottom: 15px;
                    .detalle-compra {
                        padding-bottom: 5px;
                        .MuiTypography-root {
                            h6 {
                                font-size: 0.85rem;
                            }
                            .img-content {
                                padding-left: 0px;
                            }
                            .detalle-content {
                                padding-left: 0px;
                                ul {
                                    padding-left: 0px;
                                    li {
                                        padding: 2px 0px;
                                        span {
                                            font-size: 0.82rem;
                                            &:last-child,
                                            &:first-child {
                                                padding-right: 0px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 992px) {
            .MuiPaper-root {
                .MuiButtonBase-root.MuiIconButton-root {
                    padding: 0px;
                }
                .header-acordeon {
                    .compra-id {
                        >span {
                            &.orden {
                                font-size: 0.9rem;
                            }
                            &.fecha {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
            .MuiCollapse-container {
                .MuiCollapse-wrapper {
                    padding-bottom: 10px;
                    .detalle-compra {
                        padding-bottom: 10px;
                        .MuiTypography-root {
                            h6 {
                                font-size: 0.95rem;
                            }
                            .detalle-content {
                                ul {
                                    padding-left: 0px;
                                    li {
                                        padding: 2px 0px;
                                        span {
                                            font-size: 1rem;
                                            &:last-child,
                                            &:first-child {
                                                padding-right: 0px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 1435px) {
            .img-content {
                div {
                    img {
                        height: 120px !important;
                    }
                }
            }
        }
        
        @media (min-width: 445px) and (max-width: 575px) {
            .img-content {
                div {
                    img {
                        height: 120px !important;
                    }
                }
            }
        }
        
        .btn.btn-save {
            background-color: #c52632 !important;
            color: #ffffff !important;
            width: 200px;
            border-radius: 25px;
            // border: 5px solid #fff !important;
            // box-shadow: 5px 5px 17px -6px rgba(0, 0, 0, 0.75);
        }
        
        .btn.btn-cancel {
            background-color: #333333 !important;
            color: #ffffff !important;
            width: 200px;
            border-radius: 25px;
            // border: 5px solid #fff !important;
            // box-shadow: 5px 5px 17px -6px rgba(0, 0, 0, 0.75);
        }
        
        .btn-calificar {
            background-color: #ffc107 !important;
            color: black !important;
            border-radius: 3px !important;
            text-transform: none !important;
            float: left !important;
        }
        
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: #333333 !important;
        }
        
        @media (min-width: 320px) {
            .terminosy {
                & .btn-content {
                    top: 38.6px;
                }
            }
            .date-default-page {
                & .btn-content {
                    top: 40px;
                }
            }
            .desc-problem {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .message-gratulations {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .direction-detail {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .evidenc-photo {
                & .btn-content {
                    margin-top: 1px;
                }
            }
            .modal-rate-product {
                width: 94%;
                margin: auto;
            }
            .contenedor-guarantee {
                .guarantee {
                    img.img-guarantee {
                        width: 100%;
                    }
                }
            }
        }
        
        @media (min-width: 360px) {
            .terminosy {
                & .btn-content {
                    top: 40px;
                }
            }
            .date-default-page {
                & .btn-content {
                    top: 38.6px;
                }
            }
            .desc-problem {
                & .btn-content {
                    margin-top: -9.6px;
                }
            }
            .message-gratulations {
                & .btn-content {
                    margin-top: -9.6px;
                }
            }
            .direction-detail {
                & .btn-content {
                    margin-top: -9.6px;
                }
            }
            .evidenc-photo {
                & .btn-content {
                    margin-top: 1px;
                }
            }
        }
        
        @media (min-width: 375px) {
            .terminosy {
                & .btn-content {
                    top: 42px;
                }
            }
            .date-default-page {
                & .btn-content {
                    top: 40.6px;
                }
            }
            .desc-problem {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .message-gratulations {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .direction-detail {
                & .btn-content {
                    margin-top: -10px;
                }
            }
            .evidenc-photo {
                & .btn-content {
                    margin-top: 1.4px;
                }
            }
        }
        
        @media (min-width: 411px) {
            .terminosy {
                & .btn-content {
                    top: 38.6px;
                }
            }
            .date-default-page {
                & .btn-content {
                    top: 38px;
                }
            }
            .desc-problem {
                & .btn-content {
                    margin-top: -9px;
                }
            }
            .message-gratulations {
                & .btn-content {
                    margin-top: -9px;
                }
            }
            .direction-detail {
                & .btn-content {
                    margin-top: -9px;
                }
            }
            .evidenc-photo {
                & .btn-content {
                    margin-top: 1.2px;
                }
            }
        }
        
        @media (min-width: 414px) {
            .terminosy {
                & .btn-content {
                    top: 44px;
                }
            }
            .date-default-page {
                & .btn-content {
                    top: 42.6px;
                }
            }
            .desc-problem {
                & .btn-content {
                    margin-top: -10.6px;
                }
            }
            .message-gratulations {
                & .btn-content {
                    margin-top: -10.6px;
                }
            }
            .direction-detail {
                & .btn-content {
                    margin-top: -10.6px;
                }
            }
            .evidenc-photo {
                & .btn-content {
                    margin-top: 0.8px;
                }
            }
        }
        
        @media (min-width: 1024px) {
            .modal-rate-product {
                width: 50%;
                margin: auto;
            }
            .contenedor-guarantee {
                .guarantee {
                    img {
                        width: auto;
                    }
                }
            }
        }
        
        @media (min-width: 1200px) {
            .modal-rate-product {
                width: 50%;
                margin: auto;
            }
            .contenedor-guarantee {
                .guarantee {
                    img {
                        width: auto;
                    }
                }
            }
        }

        .add-phone-link:hover {
            cursor: pointer;
        }