.main-container-jamar {
    width: 40%;
    @media screen and (max-width: 1200px) {
        width: 60%;
    }
    @media screen and (max-width: 800px) {
        width: 75%;
    }
    @media screen and (max-width: 615px) {
        width: 92%;
    }
    @media screen and (max-width: 400px) {
        width: 87%;
    }
    .secondary-container-jamar {
        width: 96%;
        @media screen and (max-width: 400px) {
            width: 90%;
        }
        margin-bottom: 1000px;
    }
}

.nav-container {
    width: 40%;
    @media screen and (max-width: 1200px) {
        width: 62%;
    }
    @media screen and (max-width: 800px) {
        width: 81%;
    }
    @media screen and (max-width: 615px) {
        width: 100%;
    }
}

.button-to-credijamar {
    position: relative;
    left: 8px;
}

#burger-more-options:hover:focus:focus-within {
    box-shadow: none;
}

#icon-burger-more-options {
    position: relative !important;
    top: 3px !important;
}

.options-burger {
    font-size: 15px !important;
    .icons-options-burger {
        font-size: 20px !important;
        position: relative;
        top: 5px;
        margin-right: 4px;
    }
}

#burger-more-options::after {
    content: none !important;
}

.icons-profile {
    position: relative;
    top: 8px;
    color: #fff;
    cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #f8f9fa !important;
    color: #16181b !important;
    text-decoration: none !important;
}

.conten-main-auth,
.conten-credijamar {
    height: inherit;
    width: 100%;
    .envoltura {
        .card-banner-credijamar {
            background-color: #00B5AD !important;
        }
        .card-banner {
            background-color: #c52632 !important;
        }
        .card-banner,
        .card-banner-credijamar {
            position: relative;
            left: 1px;
            justify-content: center !important;
            color: white;
            padding: 20px 40px 208px 40px;
            button {
                color: #fff;
            }
        }
        .main {
            position: relative;
            bottom: 199px;
            .card-main {
                font-family: OpenSans Regular;
                // padding: 70px 70px 55px 70px !important;
                text-align: left;
                border-radius: 10px;
                box-shadow: 0 3px 6px #0003;
                border: none;
            }
        }
    }
}

@media (min-width: 300px) {
    .conten-main-auth,
    .conten-credijamar {
        .envoltura {
            .card-banner,
            .card-banner-credijamar {
                .img-jamar-main {
                    width: 33%;
                    height: 17px;
                }
            }
            .main {
                .card-main {
                    bottom: 0px;
                    padding: 20px;
                }
            }
        }
    }
}

@media (min-width: 340px) {
    .conten-main-auth,
    .conten-credijamar {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-banner,
            .card-banner-credijamar {
                .img-jamar-main {
                    width: 33%;
                    height: 17px;
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-main-auth,
    .conten-credijamar {
        height: fit-content;
        .envoltura {
            .main {
                .card-main {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .conten-main-auth,
    .conten-credijamar {
        height: auto;
        .envoltura {
            .main {
                .card-main {
                    padding: 20px;
                }
            }
        }
    }
}