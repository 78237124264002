@font-face {
    font-family: HKGrotesk Regular;
    src: url(assets/fonts/hk-grotesk/HKGrotesk-Regular.ttf);
}

@font-face {
    font-family: HKGrotesk Light;
    src: url(assets/fonts/hk-grotesk/HKGrotesk-Light.ttf);
}

@font-face {
    font-family: HKGrotesk Medium;
    src: url(assets/fonts/hk-grotesk/HKGrotesk-Medium.ttf);
}

@font-face {
    font-family: HKGrotesk Bold;
    src: url(assets/fonts/hk-grotesk/HKGrotesk-Bold.ttf);
}

@font-face {
    font-family: HKGrotesk Italic;
    src: url(assets/fonts/hk-grotesk/HKGrotesk-Italic.ttf);
}

/* OpenSans */
@font-face {
    font-family: OpenSans Regular;
    src: url(assets/fonts/open-sans/OpenSans-Regular.ttf);
}
@font-face {
    font-family: OpenSans SemiBold;
    src: url(assets/fonts/open-sans/OpenSans-SemiBold.ttf);
}
@font-face {
    font-family: OpenSans Bold;
    src: url(assets/fonts/open-sans/OpenSans-Bold.ttf);
}
@font-face {
    font-family: OpenSans Light;
    src: url(assets/fonts/open-sans/OpenSans-Light.ttf);
}