
.toolbar-position {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #D8222B;
}

.toolbar-img {
  margin: 15px 26px 10px;
}

.parapagos{ 
  background: url('../../assets/images/pagos/fondo.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
body{
    background-color:transparent !important;
}

.content-app{
  margin: 0 !important;
}