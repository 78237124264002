.conten-login {
    height: inherit;
    width: 100%;
    .card-banner {
        background-color: #c52632 !important;
        position: relative;
        left: 1px;
        justify-content: center !important;
        color: white;
        font-family: OpenSans Regular;
        // padding: 80px 40px;
        padding: 20px 40px 101px 40px;
        .tittle,
        .text {
            text-align: center;
        }
    }
    .card-form {
        border: 1px solid #ccc;
        font-family: OpenSans Regular;
        // padding: 70px 70px 55px 70px !important;
        text-align: left;
        .content-btn-log {
            // background: #fff;
            padding-left: 0px !important;
            padding-right: 0px !important;
            .MuiButton-containedPrimary {
                background-color: #c42531 !important;
                border-radius: 22px;
                text-transform: none;
                font-weight: 600;
                font-family: OpenSans Regular;
            }
        }
    }
    .content-btn-log {
        position: absolute;
        // box-shadow: 0px 3px 6px #00000045;
        border-radius: 22px;
        padding: 10px;
        margin-top: 0px;
        width: auto;
        top: auto;
        &:nth-child(1) {
            order: 1;
        }
        &:nth-child(2) {
            order: 2;
            margin-bottom: 1rem;
        }
        &:nth-child(3) {
            order: 3;
            margin-bottom: 1rem;
        }
    }
}

h6 {
    font-family: HKGrotesk Regular !important;
}

@media (min-width: 300px) {
    .conten-login {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-banner {
                .tittle,
                .text {
                    font-family: OpenSans Regular;
                    font-size: 0.6rem;
                    line-height: 14px;
                }
                .text {
                    margin-top: 10px;
                }
            }
            .card-form {
                padding: 20px;
                padding-bottom: 40px;
                border-radius: 19px;
                box-shadow: 0px 3px 6px #00000045;
                .options {
                    border-bottom: 1px solid #ccc;
                    span {
                        font-size: 0.56rem;
                        font-weight: 600;
                        cursor: pointer;
                        font-family: OpenSans Regular;
                        &:first-child {
                            border-bottom: 1px solid #c2232f;
                        }
                    }
                }
                h4 {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
                label,
                input {
                    font-size: 0.75rem;
                    font-family: OpenSans Regular;
                }
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.62rem;
                        margin: 0px;
                    }
                    p {
                        font-family: OpenSans Regular;
                        margin-top: 2px;
                        text-align: right;
                    }
                    #forgot-pass-login {
                        text-decoration: underline;
                        font-size: 0.62rem;
                    }
                    #forgot-pass-login:hover {
                        text-decoration: underline;
                        color: black;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (min-width: 340px) {
    .conten-login {
        .envoltura {
            .card-banner {
                .tittle,
                .text {
                    font-size: 0.8rem;
                    line-height: 14px;
                }
                .text {
                    margin-top: 10px;
                }
            }
            .card-form {
                padding: 20px;
                padding-bottom: 20px;
                .options {
                    span {
                        font-size: 0.68rem;
                        cursor: pointer;
                        &:first-child {
                            border-bottom: 1px solid #c2232f;
                        }
                    }
                }
                h4 {
                    font-size: 1rem;
                    margin-bottom: 1rem;
                }
                label,
                input {
                    font-size: 0.8rem;
                }
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.8rem;
                        margin: 0px;
                    }
                    p {
                        margin-top: 2px;
                        text-align: right;
                    }
                    #forgot-pass-login {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-login {
        height: fit-content;
        .card-banner {
            .tittle,
            .text {
                font-size: 0.9rem;
                line-height: 16px;
            }
        }
        .card-form {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }
    }
}

@media (min-width: 768px) {
    .conten-login {
        height: auto;
        .envoltura {
            .card-banner {
                .tittle {
                    font-size: 1.1rem;
                    line-height: 21px;
                }
                .text {
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
            .card-form {
                padding: 35px 35px;
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.7rem;
                    }
                    p {
                        margin-top: 3px;
                    }
                }
                .cont-buttons {
                    margin-top: 1rem;
                    .center-horizontal {
                        &:nth-child(1) {
                            order: 1;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(2) {
                            order: 2;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(3) {
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}

#info-id-panama {
    padding: 3px 0px 0px 20px;
    font-size: 12px;
}

.container-login-with-banner {
    height: auto;
    transform: translateY(-75px);
    gap: 10px;
}


.portal-banner {
    width: 100%;
    a img {
        border-radius: 20px;
        min-height: 160px;
        background-color: #ffffff74;
        @media only screen and (max-width: 500px) {
            min-height: 80px;
            border-radius: 0px;
        }
    }
}