.half-background {
  &:before {
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 268px;
    background-color: #00b5ad;
    z-index: -1;
  }
}
#content-header{
  margin-top: 20px;
}
.root-container {
    width: 40%;
    margin-top: 17px;

    @media screen and (max-width: 1200px) {
        width: 60%;
    }
    @media screen and (max-width: 800px) {
        width: 75%;
    }
    @media screen and (max-width: 615px) {
        width: 92%;
    }

    @media screen and (max-width: 400px) {
        width: 87%;
    }
}