.conten-menu-module {
    height: inherit;
    width: 100%;
    .card-banner-module {
        background-color: #c52632 !important;
        position: relative;
        left: 1px;
        justify-content: center !important;
        color: white;
        padding: 20px 40px 208px 40px;
        .publicidad {
            height: 40px;
        }
    }
    .card-form-module {
        border: 1px solid #ccc;
        font-family: OpenSans Regular;
        width: 40%;
        margin-top: 17px;

        @media screen and (max-width: 1200px) {
            width: 60%;
        }
        @media screen and (max-width: 615px) {
            width: 72%;
        }

        @media screen and (max-width: 400px) {
            width: 87%;
        }

        // padding: 70px 70px 55px 70px !important;
        text-align: left;
        .credijamar {
            .content-img {
                background: #00b5ad;
            }
        }
        .jamar {
            .content-img {
                background: #d8242a;
            }
        }
        .credijamar,
        .jamar {
            .item-order {
                .item-uno {
                    order: 1;
                }
                .item-dos {
                    order: 2;
                    .content-img {
                        border-radius: 10px;
                        height: 150px;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.card-form-module .item-uno {
    margin: 10px auto !important;
}

@media (min-width: 300px) {
    .conten-menu-module {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-banner-module {
                .publicidad {
                    height: 177px;
                }
            }
            .card-form-module {
                position: absolute;
                top: 80px;
                padding: 20px;
                border-radius: 19px;
                box-shadow: 0px 3px 6px #00000045;
                .options {
                    border-bottom: 1px solid #ccc;
                    span {
                        font-size: 0.78rem;
                        font-weight: 600;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
                .jamar {
                    .item-order {
                        .item-dos {
                            .content-img {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
                .credijamar,
                .jamar {
                    .item-order {
                        .item-uno {
                            margin-bottom: 3px;
                            line-height: 15px;
                            font-size: 0.8rem;
                            text-align: center;
                        }
                        .item-dos {
                            .content-img {
                                height: 117px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 340px) {
    .conten-menu-module {
        .envoltura {
            .card-banner-module {
                .publicidad {
                    height: 177px;
                }
            }
            .card-form-module {
                padding: 20px;
                .options {
                    span {
                        font-size: 0.78rem;
                    }
                }
                .jamar {
                    .item-order {
                        .item-dos {
                            .content-img {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
                .credijamar,
                .jamar {
                    .item-order {
                        .item-uno {
                            margin-bottom: 3px;
                            line-height: 18px;
                            font-size: 1rem;
                        }
                        .item-dos {
                            .content-img {
                                height: 117px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-menu-module {
        height: fit-content;
        .card-form-module {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            .credijamar,
            .jamar {
                .item-order {
                    .item-uno {
                        text-align: justify;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .conten-menu-module {
        height: auto;
        .envoltura {
            .card-form-module {
                padding: 25px 35px;
            }
        }
    }
}
@media (min-width: 992px) {
    .conten-menu-module {
        height: auto;
        .envoltura {
            .card-form-module {
                // box-shadow: none;
                // border-radius: 0px;
                border: 0px;
                position: relative;
                top: -158px;
                .options {
                    border-bottom: 0px;
                    span {
                        font-size: 0.9rem;
                    }
                }
                .credijamar,
                .jamar {
                    .item-order {
                        .item-uno {
                            order: 2;
                        }
                        .item-dos {
                            order: 1;
                            .content-img {
                                margin-bottom: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}
