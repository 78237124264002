.submit-password {
  position: relative;
  align-self: center;
  top: 40px;
  border: none;
  width: 40%;
  background-color: #c52632;
  text-align: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50px;
  padding: 8px;
  transition: all 0.2s;

  @media screen and (max-width: 700px) {
    width: 70%;
  }
}

.error-text {
  color: #c52632;
}
