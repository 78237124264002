.conten-register {
    height: inherit;
    widows: 100%;
    .card-banner {
        background-color: #c52632 !important;
        position: relative;
        left: 1px;
        justify-content: center !important;
        color: white;
        padding: 133px 40px;
        .tittle,
        .text {
            text-align: center;
        }
    }
    .card-form {
        background: #fff;
        border: 1px solid #ccc;
        font-family: OpenSans Regular;
        // padding: 70px 70px 55px 70px !important;
        text-align: left;
        .content-btn-log {
            // background: #fff;
            .MuiButton-containedPrimary {
                background-color: #c42531 !important;
                border-radius: 22px;
            }
        }
    }
}

#forgot-pass {
    text-decoration: underline;
    font-weight: bold;
    font-size: 0.75rem;
}

#forgot-pass:hover {
    font-weight: bold;
    text-decoration: underline;
    color: black;
    cursor: pointer;
}

h6 {
    font-family: HKGrotesk Regular !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #c52632 !important;
}

@media (min-width: 300px) {
    .conten-register {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-banner {
                img {
                    position: absolute;
                    top: 23px;
                }
            }
            .card-form {
                position: absolute;
                top: 80px;
                padding: 20px;
                padding-bottom: 40px;
                border-radius: 19px;
                box-shadow: 0px 3px 6px #00000045;
                .options {
                    border-bottom: 1px solid #ccc;
                    span {
                        font-size: 0.62rem;
                        font-weight: 600;
                        cursor: pointer;
                        &:last-child {
                            border-bottom: 1px solid #c2232f;
                        }
                    }
                }
                .inputs-register {
                    overflow: hidden;
                    overflow-y: scroll;
                    height: 400px;
                    label,
                    input {
                        font-size: 0.65rem;
                    }
                    .term-text {
                        font-size: 0.6rem;
                    }
                    .check-forgetpass {
                        label,
                        p {
                            font-size: 0.65rem;
                            margin: 0px;
                        }
                        p {
                            margin-top: 2px;
                            text-align: right;
                        }
                    }
                }
                .content-btn-log {
                    position: absolute;
                    // box-shadow: 0px 3px 6px #00000045;
                    border-radius: 22px;
                    padding: 10px;
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(3) {
                        order: 3;
                        margin-bottom: 1rem;
                    }
                    h6 {
                        font-size: 0.7rem;
                        display: block;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: 340px) {
    .conten-register {
        .envoltura {
            .card-banner {
                img {
                    top: 23px;
                }
            }
            .card-form {
                top: 80px;
                padding: 20px;
                padding-bottom: 40px;
                .options {
                    span {
                        font-size: 0.68rem;
                        font-weight: 600;
                        cursor: pointer;
                        &:last-child {
                            border-bottom: 1px solid #c2232f;
                        }
                    }
                }
                .inputs-register {
                    height: 350px;
                    label,
                    input {
                        font-size: 0.8rem;
                    }
                    .check-forgetpass {
                        label,
                        p {
                            font-size: 0.8rem;
                            margin: 0px;
                        }
                        p {
                            margin-top: 2px;
                        }
                    }
                }
                .content-btn-log {
                    padding: 10px;
                    &:nth-child(1) {
                        order: 1;
                    }
                    &:nth-child(2) {
                        order: 2;
                        margin-bottom: 1rem;
                    }
                    &:nth-child(3) {
                        order: 3;
                        margin-bottom: 1rem;
                    }
                    h6 {
                        font-size: 0.7rem;
                        display: block;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-register {
        height: fit-content;
        .envoltura {
            .card-banner {
                .tittle,
                .text {
                    font-size: 0.9rem;
                    line-height: 16px;
                }
            }
            .card-form {
                top: 100px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
    }
}

@media (min-width: 768px) {
    .conten-register {
        height: auto;
        .envoltura {
            .card-banner {
                .tittle {
                    font-size: 1.1rem;
                    line-height: 21px;
                }
                .text {
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
            .card-form {
                top: 90px;
                padding: 35px 35px;
                .check-forgetpass {
                    label,
                    p {
                        font-size: 0.7rem;
                    }
                    p {
                        margin-top: 3px;
                    }
                }
                .cont-buttons {
                    margin-top: 1rem;
                    .center-horizontal {
                        &:nth-child(1) {
                            order: 1;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(2) {
                            order: 2;
                            margin-bottom: 1rem;
                        }
                        &:nth-child(3) {
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .conten-register {
        .envoltura {
            .card-form {
                top: 93px;
            }
        }
    }
}