body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button:focus,
.btn.focus,
.btn:focus {
    box-shadow: none !important;
    outline: none !important;
}


/* CLASE PAARA CENTRAR CONTENEDOR */

.v-center {
    float: none !important;
    margin: 0 auto !important;
}

.card-content {
    .button-container {
        background-color: transparent !important;
        box-shadow: none !important;
    }
}

.swal2-actions {
    & button.swal2-confirm {
        font-size: 0.9rem;
        width: 220px;
        border-radius: 22px !important;
        font-family: OpenSans SemiBold !important;
    }
}

@media (min-width: 320px) {
    .btn-content {
        text-align: center !important;
        & .MuiButtonBase-root {
            width: 128px;
            font-size: 0.7rem;
            border-radius: 22px !important;
            margin: 4px 1px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 128px;
                font-size: 0.7rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 360px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 142px;
            font-size: 0.7rem;
            border-radius: 22px !important;
            margin: 4px 1px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 142px;
                font-size: 0.7rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 375px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 130px;
            font-size: 0.8rem;
            border-radius: 22px !important;
            margin: 4px 2px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 124px;
                font-size: 0.8rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 411px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 174px;
            font-size: 0.85rem;
            border-radius: 22px !important;
            margin: 0px 3px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 174px;
                font-size: 0.85rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 414px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 170px;
            font-size: 0.9rem;
            border-radius: 22px !important;
            margin: 4px 2px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 176px;
                font-size: 0.9rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 1024px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 190px;
            font-size: 0.9rem;
            border-radius: 22px !important;
            margin: 0px 3px !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                width: 210px;
                font-size: 0.9rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@media (min-width: 1200px) {
    .btn-content {
        text-align: center !important;
        display: inline-block !important;
        & .MuiButtonBase-root {
            width: 190px;
            font-size: 0.9rem;
            border-radius: 22px !important;
            margin: auto !important;
            font-family: OpenSans SemiBold !important;
        }
    }
    // CARD
    .card-content {
        .button-container {
            width: 50%;
            text-align: center !important;
            & .button {
                text-align: center !important;
                width: 220px;
                font-size: 0.9rem;
                border-radius: 22px !important;
                margin: auto !important;
                font-family: OpenSans SemiBold !important;
            }
        }
    }
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(/assets/fonts/Poppins-Thin.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}