.content-text {
    margin-top: 15px;
    margin-bottom: 15px;
}

.content-img-onboarding-step {
    width: 70vh;
    max-width: 100%;
    padding: 0 15px;

    @media screen and (max-width: 600px) {
        width: 40vh;
    }
} 
.card-form-onboarding {
    position: relative !important;
    bottom: 170px;
}

.header-onboarding {
    width: 40%;
    @media screen and (max-width: 1200px) {
        width: 62%;
    }
    @media screen and (max-width: 800px) {
        width: 81%;
    }
    @media screen and (max-width: 615px) {
        width: 95%;
    }
}

.onboarding-credijamar .contendor,
.onboarding-jamar .contendor {
    border-radius: 10px;
    background-color: white;
    box-shadow: 5px 5px 17px -6px rgba(0, 0, 0, 0.75);
}

.btn.btn-secondary-app.btn-back {
    background-color: #00000000 !important;
    color: #ffffff !important;
    font-size: 20px;
    font-weight: bolder;
    border: none !important;
}

.btn.btn-secondary-app.btn-skip {
    background-color: #00000000 !important;
    color: #ffffff !important;
    text-decoration: underline;
    font-weight: bolder;
    border: none !important;
}

.color-jamar .title-page-onboarding,
.color-jamar .description-page-onboarding {
    color: #212121 !important;
}

.color-credijamar .title-page-onboarding,
.color-credijamar .description-page-onboarding {
    color: #212121 !important;
}

.conten-login {
    height: inherit;
    width: 100%;
    .envoltura {
        .card-banner-onboarding {
            position: relative;
            left: 1px;
            justify-content: center !important;
            color: white;
            padding: 20px 40px 208px 40px;
        }
        .card-form-onboarding {
            border: 1px solid #ccc;
            font-family: OpenSans Regular;
            // padding: 70px 70px 55px 70px !important;
            text-align: left;
            .main-onboarding {
                .content-img-onboarding {
                    padding: 25px 0px;
                    .title-page-onboarding,
                    .description-page-onboarding {
                        font-family: OpenSans Regular;
                        color: #333333 !important;
                    }
                    .description-page-onboarding {
                        margin-bottom: 0px;
                        font-size: 0.7rem;
                        line-height: 13px;
                        padding: 0px 14px;
                    }
                }
                .content-next {
                    position: absolute;
                    bottom: -14px;
                    .btn.btn-secondary-app.btn-next.btn-jamar,
                    .btn.btn-secondary-app.btn-next.btn-credijamar {
                        color: #ffffff !important;
                    }
                    .btn.btn-secondary-app.btn-next.btn-jamar {
                        background-color: #c52632 !important;
                        border: 1px solid #c52632 !important;
                    }
                    .btn.btn-secondary-app.btn-next.btn-credijamar {
                        background-color: #02b5ad !important;
                    }
                }
            }
        }
        .onboarding-jamar {
            background-color: #c52632;
            border: 1px solid #c52632 !important;
        }
        .onboarding-credijamar {
            background-color: #02b5ad;
        }
    }
}

@media (min-width: 300px) {
    .conten-login {
        height: fit-content;
        .envoltura {
            position: relative;
            .card-form-onboarding {
                position: absolute;
                padding: 20px;
                padding-bottom: 40px;
                border-radius: 19px;
                box-shadow: 0px 3px 6px #00000045;
            }
        }
    }
}

@media (min-width: 340px) {
    .conten-login {
        .envoltura {
            position: relative;
            .card-form-onboarding {
                padding: 20px;
                padding-bottom: 40px;
                border-radius: 19px;
            }
        }
    }
}

@media (min-width: 576px) {
    .conten-login {
        height: fit-content;
        .envoltura {
            .card-form-onboarding {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }
        }
    }
}

@media (min-width: 768px) {
    .conten-login {
        height: auto;
        .envoltura {
            .card-form-onboarding {
                padding: 15px;
            }
        }
    }
}

// ------------------------- //
@media (min-width: 320px) {
    .main-onboarding {
        & .btn-content {
            top: 6px;
        }
    }
}

@media (min-width: 360px) {
    .main-onboarding {
        & .btn-content {
            top: 6.2px;
        }
    }
}

@media (min-width: 375px) {
    .main-onboarding {
        & .btn-content {
            top: 6.4px;
        }
    }
}

@media (min-width: 411px) {
    .main-onboarding {
        & .btn-content {
            top: 7px;
        }
    }
}

@media (min-width: 414px) {
    .main-onboarding {
        & .btn-content {
            top: 9px;
        }
    }
}

@media (min-width: 1024px) {
    .main-onboarding {
        & .btn-content {
            top: 6px;
        }
    }
}

@media (min-width: 1200px) {
    .main-onboarding {
        & .btn-content {
            top: 6px;
        }
    }
}