.navbar-credi-jamar {
    background-color: #00B5AD !important;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 40%;

    @media screen and (max-width: 1200px) {
        width: 60%;
    }
    @media screen and (max-width: 800px) {
        width: 75%;
    }
    @media screen and (max-width: 615px) {
        width: 92%;
    }

    @media screen and (max-width: 400px) {
        width: 87%;
    }
}

#burger-more-options {
    color: white;
}

.nav-jamar-logo {
    background-color: #c52632;
    border-radius: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        height: 10px;
    }

    &:hover {
        cursor: pointer;
    }
}

i.menu {
    color: white;
    font-size: 30px;
}

.navbar-credi-jamar .logo {
    height: 30px;
    padding: 0;
}