
/* COMPONENTE DE BANNER */
.container-banner-recurrency {
    width: 100%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin: 20px 0px;
    display: flex;
    box-shadow: 0px 0px 10px 5px #0000001a;
    @media screen and (max-width:1200px) {
        display:flex;
        justify-content:flex-end;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .loading-content-portal {
        height: 300px;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
        width: 100%;
        div {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border-radius: 50%;
            border: 3px solid #127a67;
            animation: load 1s infinite linear;
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                left: 0px;
                border-radius: 50%;
                background-color: #fff;
            }

        }
    }
    .lazy-load{
        width: 100%;
        min-width: 40px;
        background-color: #ffffff;
        height: 300px;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction: column;
        p{
            text-align: center;
            @media screen and (max-width:900px) {
                margin-top: 10px;
            }
        }
        div {
            position: relative;
            width: 40px;
            height: 40px;
            background-color: transparent;
            border-radius: 50%;
            border: 3px solid #127a67;
            animation: load 1s infinite linear;
            text-align: center;
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                left: 0px;
                border-radius: 50%;
                background-color: #fff;
            }

        }
    }
    @keyframes load {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
}
.container-interaction-recurrency {
    background-color: #127a67;
    padding: 10px 20px;
    display:flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction: column;
    width: 60%;
    @media screen and (max-width:1400px) {
        width: 100%;
    }
    .banner-recurrency-title {
        font-size: 20px;
        width: 90%;
        color: #fff;
        @media screen and (max-width:1400px) {
            width: 90%;
            font-size: 18px;
            position: relative;
        }
    }
}

.banner-information {
    font-size: 16px;
    font-weight: 300;
    margin-top: 8px;
    width: 90%;
    color: #fff;
    @media screen and (max-width:1400px) {
        font-size: 14px;
        width: 90%;
        position: relative;
        z-index: 10;
    }
}

.banner-img-recurrency {
    width: 40%;
    @media screen and (max-width:900px) {
        z-index: 1;
        top: 0px;
        width: 100%;
    }
}


.enable-recurrency {
    position: relative;
    width: 200px;
    height: 50px;
    font-size: 16px;
    margin-top: 15px;
    background-color: #99eddc;
    border-radius: 8px;
    border: 0px;
    display:flex;
    justify-content:center;
    align-items:center;
    text-decoration: none;
    cursor: pointer;
    color: #127a67;
    z-index: 10;
    &:visited, &:hover {
        color: #127a67;
    }
    @media screen and (max-width:900px) {
        width: 130px;
        font-size: 14px;
        height: 40px;
    }
}

.container-error {
    margin: 10px 0px;
    font-size: 14px;
    color: rgb(255, 85, 85);
}

.content-view-router {
    margin-top: 60px;
}