.red-banner {
  background-color: #c52632 !important;
  position: absolute;
  width: 100%;
  height: 17rem;
  top: 0;
  left: 0;
}

.main-container {
  width: 100%;
  z-index: 1;
  padding-top: 1.3rem;
  width: 33.33%;
  text-align: center;

  .jamar-logo > img {
    width: 44%;
  }

  .welcome {
    color: white;
    margin-bottom: 50px;

    .title {
      font-size: 1.17rem;
      margin-top: 1.2rem;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
  }
  @media screen and (max-width: 950px) {
    width: 90%;
  }
}
