#container {
  display: flex;
  flex-direction: column;

  .MuiSelect-icon {
    top: 9px !important;
    right: 10px !important;
    font-size: 35px !important;
  }

  .choose-document {
    display: grid;
    text-align: center;
    margin: auto;
    grid-template-areas:
      "extract     peace"
      "certified   account";
    grid-template-columns: repeat(2, 0.8fr);
    row-gap: 40px;
    column-gap: 20px;
    color: #aaa;
    margin-bottom: 20px;
    margin-top: 20px;

    .extract {
      grid-area: extract;
    }
    .peace {
      grid-area: peace;
    }
    .account {
      grid-area: account;
    }

    &.panama {
      display: grid;
      grid-template-areas:
        "extract     peace"
        "account   account";
      grid-template-columns: repeat(2, 1fr);
    }

    .choice-item {
      &:hover {
        cursor: pointer;
      }

      &.selected {
        color: #00b5ad;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .choice-item-icon {
        margin-bottom: 10px;
      }

      .choice-item-name {
        font-size: 20px;
      }
    }
  }

  .document-action {
    padding-top: 20px;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;

    .document-action-title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;

      & svg {
        font-size: 30px !important;
        margin-right: 10px;
      }
    }
  }
}

#extractSelect {
  background-color: #f6f6f6;
  border-radius: 35px !important;
  border: none !important;
  padding: 16px;
  width: 20rem;

  @media screen and (max-width: 400px) {
    width: 15rem;
  }
}

.select-tip {
  font-size: 1rem;
}

#select-label {
  position: absolute;
  top: 35px;
  left: 30px;
  z-index: 1;
  color: #666 !important;

  &.MuiInputLabel-shrink {
    display: none !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.PrivateNotchedOutline-legendLabelled-63,
.PrivateNotchedOutline-legendNotched-64 {
  position: relative;
  top: 100px !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

.instructions {
  text-align: center;
  font-size: 1rem;
  color: #555;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}